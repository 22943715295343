<script setup lang="ts">
const properties = defineProps({
  icon: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: false,
    default: ''
  }
})
</script>

<template>
  <v-card elevation="16" max-width="300" height="450" class="bg-primary">
    <v-card-text class="h-100">
      <v-row class="h-100" justify="start">
        <v-col cols="12" align="start">
          <div class="pt-6 pl-2">
            <Icon :item="properties.icon" :size="75" :stroke-width="1" />
          </div>
        </v-col>
        <v-col cols="12" class="px-6 pt-4" align="start">
          <h4 class="text-h4">{{ properties.title }}</h4>
          <p class="text-body-1 pt-4">
            {{ properties.text }}
          </p>
        </v-col>
        <v-col cols="12" align="start">
          <v-btn
            v-if="properties.link"
            class="mt-8"
            color="accent"
            variant="text"
            flat
            :href="properties.link"
          >
            Mehr erfahren >>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
