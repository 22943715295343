<script setup lang="ts">
import * as icons from '@tabler/icons-vue'

const properties = defineProps({
  item: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    default: 20
  },
  strokeWidth: {
    type: Number,
    default: 1.5
  }
})

const icon: icons.Icon = icons[properties.item]

defineComponent({
  name: 'IconComponent'
})
</script>

<template>
  <component
    :is="icon"
    :size="properties.size"
    :stroke-width="properties.strokeWidth"
    class="iconClass"
  />
</template>
